import type { AuthFetch } from '~/types/globalDeclarations/AuthFetch'

/**
 * .
 * Provider to request a password change.
 *
 * @param { AuthFetch } $authFetch - The transporter.
 * @returns { Function } The api call function.
 */
export function provideAuthRequestPasswordReset($authFetch: AuthFetch) {
  return function (email: string) {
    return $authFetch.$post<void>('/api/password/reset/', { email })
  }
}

/**
 * Provider to actually apply a password change.
 * This is not the same endpoint as the one used for the settings.
 * This is only for a password recovery.
 *
 * @param { AuthFetch } $authFetch - The transporter.
 * @returns { Function } The api call function.
 */
export function provideAuthConfirmPasswordChange($authFetch: AuthFetch) {
  return function (payload: {
    new_password1: string
    new_password2: string
    uid: string
    token: string
  }) {
    return $authFetch.$post<void>('/api/password/reset/confirm/', payload)
  }
}
